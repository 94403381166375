import * as React from 'react'


const WastewaterAuditForm = () => {
  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: `
          <!--Zoho Campaigns Web-Optin Form's Header Code Starts Here-->

          <meta content="width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport">
          <script type="text/javascript" src="https://wesu.maillist-manage.com/js/optin.min.js" onload="setupSF('sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0','ZCFORMVIEW',false,'acc',false,'2')"></script>
          <script type="text/javascript">
	          function runOnFormSubmit_sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0(th){
		          /*Before submit, if you want to trigger your event, "include your code here"*/
	          };
          </script>

          <style>
            #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 #customForm *:not(.dateClass){
              -webkit-box-sizing: border-box !important;
              -moz-box-sizing: border-box !important;
              box-sizing: border-box !important;
              word-break:break-word;
              overflow-wrap: break-word;
            }
            #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 .dateClass{
              -webkit-box-sizing: unset!important;
              -moz-box-sizing: unset !important;
              box-sizing: unset !important;
              word-break:break-word;
              overflow-wrap: break-word;
            }
            /*** RESPONSIVE START */
            @media only screen and (max-width: 200px){
              #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 #signupMainDiv{
                width:100%!important;
                min-width:100% !important;
                margin: 0px auto !important;
              }
              #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 #SIGNUP_PAGE{
                padding:0px !important	
              }
              #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 .recaptcha{
                transform:scale(0.55);-webkit-transform:scale(0.45);transform-origin:0 0;-webkit-transform-origin:0 0;
              }
            }
            @media only screen and (min-width: 440px) and (max-width:600px){
              #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 #signupMainDiv{
                width:100% !important;
                min-width:100% !important;
                margin: 0px auto !important;	
              }
              #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 .recaptcha{
                transform:scale(0.72);-webkit-transform:scale(0.55);transform-origin:0 0;-webkit-transform-origin:0 0;
              }
            }
            @media only screen and (min-width: 320px) and (max-width:440px){
              #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 #signupMainDiv{
                width:100% !important;
                min-width:100% !important;
                margin: 0px auto !important;
              }
              #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 .recaptcha{
                transform:scale(0.72);-webkit-transform:scale(0.55);transform-origin:0 0;-webkit-transform-origin:0 0;
              }
            }
            /* Desktops and laptops ----------- */
            @media only screen  and (min-width : 1224px) {
              #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 #signupMainDiv{
                width:100% !important;
                min-width:100% !important;
                margin: 0px auto !important;	
              }
              #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 .recaptcha{
                transform:scale(1.03);-webkit-transform:scale(1.08);transform-origin:0 0;-webkit-transform-origin:0 0;
              }
            }
            /* Large Screens */
            @media only screen  and (min-width : 1824px) {
              #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 #signupMainDiv{
                width:100% !important;
                min-width:600px !important;
                margin: 0px auto !important;	
              }
              #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 .recaptcha{
                transform:scale(1.03);-webkit-transform:scale(1.08);transform-origin:0 0;-webkit-transform-origin:0 0;
              }
            }
            /* iPads (portrait and landscape) ----------- */
            @media only screen and (min-device-width : 601px) and (max-device-width : 1024px) {
              #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 #signupMainDiv{
                width:100% !important;
                min-width:100% !important;
                margin: 0px auto !important;	
              }
              #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 .recaptcha{
                transform:scale(0.90);-webkit-transform:scale(0.90);transform-origin:0 0;-webkit-transform-origin:0 0;
              }
            }
            /*** RESPONSIVE END */
            #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 .bdr_btm_hover{background-color:#f9f9f9; padding:10px;}
            #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 .bdr_btm{padding:10px }
            #sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0 .f14{font-size:14px}
          </style>

          <!--Zoho Campaigns Web-Optin Form's Header Code Ends Here--><!--Zoho Campaigns Web-Optin Form Starts Here-->

          <div id="sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0" data-type="signupform">
	          <input type="hidden" id="recapTheme" value="2">
	          <input type="hidden" id="isRecapIntegDone" value="false">
	          <input type="hidden" id="signupFormType" value="LargeForm_Vertical">
	          <div id="customForm">
		          <div name="SIGNUP_PAGE" class="large_form_3_css" id="SIGNUP_PAGE" style="background-color: rgb(247, 247, 247); color: rgb(255, 255, 255); font-family: Arial; text-align: center; font-size: 14px; min-width: 240px;">
			          <div name="" changeid="" changename="" style="margin:0px auto">
				          <div id="imgBlock" name="LOGO_DIV" logo="true" style="width: 600px; margin: 0px auto; padding-bottom: 10px; text-align: center; padding-right: 10px; padding-top: 10px;"></div>
			          </div>
			          <br>
			          <div id="signupMainDiv" style="margin: 0px auto; width: 100%; min-width: 230px; max-width: 600px;" name="SIGNUPFORM" changeid="SIGNUPFORM" changename="SIGNUPFORM">
				          <div>
					          <div style="position:relative;">
						          <div id="Zc_SignupSuccess" style="display:none;position:absolute;margin-left:4%;width:90%;background-color: white; padding: 3px; border: 3px solid rgb(194, 225, 154);  margin-top: 10px;margin-bottom:10px;word-break:break-all">
							          <table width="100%" cellpadding="0" cellspacing="0" border="0">
								          <tbody>
									          <tr>
										          <td width="10%">
											          <img class="successicon" src="https://wesu.maillist-manage.com/images/challangeiconenable.jpg" align="absmiddle">
										          </td>
										          <td>
											          <span id="signupSuccessMsg" style="color: rgb(73, 140, 132); font-family: sans-serif; font-size: 14px;word-break:break-word">&nbsp;&nbsp;Thank you for Signing Up</span>
										          </td>
									          </tr>
								          </tbody>
							          </table>
						          </div>
					          </div>
					        <form method="POST" id="zcampaignOptinForm" style="margin:0px;" action="https://wesu.maillist-manage.com/weboptin.zc" target="_zcSignup">
						        <div id="SIGNUP_BODY_ALL" name="SIGNUP_BODY_ALL" style="border-color: rgb(186, 32, 37); border-width: 2px; border-style: dashed;">
							        <h1 style="color: rgb(67, 67, 67); background-color: rgb(245, 245, 245); border: 1px none rgb(91, 91, 91); padding: 20px; margin: 0px; font-weight: bold; text-align: left; font-size: 28px;" id="SIGNUP_HEADING" name="SIGNUP_HEADING" changeid="SIGNUP_MSG" changetype="SIGNUP_HEADER">Download your wastewater audit checklist now</h1>
							        <div style="background-color: rgb(247, 247, 247); color: rgb(68, 68, 68); padding: 20px; font-family: Arial; text-align: center; bottom: 2px; font-size: 16px; opacity: 1;" id="SIGNUP_BODY" name="SIGNUP_BODY">
								        <div style="margin:0px auto;text-align:left;">
									        <div style="line-height:1.6;" class="" changeid="SIGNUP_MSG" id="SIGNUP_DESCRIPTION" changetype="SIGNUP_DESCRIPTION">Learn the 10 critical steps to completing a wastewater audit that will get your team recognized.</div>
									        <div style="display:none;background-color:#FFEBE8;padding:10px 10px; color:#d20000; font-size:11px; margin:10px 0px;border:solid 1px #ffd9d3; margin-top:20px;" id="errorMsgDiv">&nbsp;&nbsp;Please correct the marked field(s) below.</div>
									        <div>
										        <div style="font-size:12px; margin-top:10px;" name="fieldsdivSf" class="zcsffieldsdiv">
											        <div style="padding:10px 0px 10px 0px;" class="zcsffield " fieldid="540640000000000023">
												        <div style=""><!-- check to mark emailid field as type email, and other mandatory fields as type required -->
													        <input style="width: 97%; color: rgb(68, 68, 68); background-color: rgb(247, 247, 247); border: 2px solid rgb(36, 85, 141); padding: 15px 10px; font-family: Arial; box-sizing: border-box; font-size: 16px; text-indent: 5px;" maxlength="100" placeholder="First Name" name="FIRSTNAME" changeitem="SIGNUP_FORM_FIELD" type="text">&nbsp;
													        <span style="display:none" id="dt_FIRSTNAME">1,false,1,First Name,2</span>
												        </div>
												        <div style="clear:both"></div>
											        </div>
											        <div style="padding:10px 0px 10px 0px;" class="zcsffield " fieldid="540640000000000025">
												        <div style=""><!-- check to mark emailid field as type email, and other mandatory fields as type required -->
													        <input style="width: 97%; color: rgb(68, 68, 68); background-color: rgb(247, 247, 247); border: 2px solid rgb(36, 85, 141); padding: 15px 10px; font-family: Arial; box-sizing: border-box; font-size: 16px; text-indent: 5px;" maxlength="100" placeholder="Last Name" name="LASTNAME" changeitem="SIGNUP_FORM_FIELD" type="text">&nbsp;
													        <span style="display:none" id="dt_LASTNAME">1,false,1,Last Name,2</span>
												        </div>
                                <div style="clear:both"></div>
                              </div>
											        <div style="padding:10px 0px 10px 0px;" class="zcsffield " fieldid="540640000000000027">
												        <div style=""><!-- check to mark emailid field as type email, and other mandatory fields as type required -->
													        <input style="width: 97%; color: rgb(68, 68, 68); background-color: rgb(247, 247, 247); border: 2px solid rgb(36, 85, 141); padding: 15px 10px; font-family: Arial; box-sizing: border-box; font-size: 16px; text-indent: 5px;" maxlength="100" placeholder="Company Name" name="COMPANYNAME" changeitem="SIGNUP_FORM_FIELD" type="text">&nbsp;
													        <span style="display:none" id="dt_COMPANYNAME">1,false,1,Company Name,2</span>
												        </div>
                                <div style="clear:both"></div>
                              </div>
											        <div style="padding:10px 0px 10px 0px;" class="zcsffield " fieldid="540640000000000021">
												        <div style=""><!-- check to mark emailid field as type email, and other mandatory fields as type required -->
													        <input style="width: 97%; color: rgb(68, 68, 68); background-color: rgb(247, 247, 247); border: 2px solid rgb(36, 85, 141); padding: 15px 10px; font-family: Arial; box-sizing: border-box; font-size: 16px; text-indent: 5px;" maxlength="100" placeholder="Work email" name="CONTACT_EMAIL" changeitem="SIGNUP_FORM_FIELD" type="email">&nbsp;
													        <span name="SIGNUP_REQUIRED" style="color: rgb(180, 0, 0); font-family: Arial; font-size: 11px;">*</span>
													        <span style="display:none" id="dt_CONTACT_EMAIL">1,true,6,Contact Email,2</span>
												        </div>
                                <div style="clear:both"></div>
                              </div>
										        </div><!-- Captcha for Signup -->
										        <div style="padding: 10px 0px 10px 10px;display:none " id="captchaOld" name="captchaContainer">
											        <div style="">
												        <div style="width: 59%; float: left; min-width: 170px; max-width: 70%;" id="captchaParent">
													        <img src="//campaigns.zoho.com/images/refresh_icon.png" style="cursor: pointer;float:right;margin-right:4px" onclick="loadCaptcha('https://campaigns.zoho.com/campaigns/CaptchaVerify.zc?mode=generate',this,'#sf3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0');" id="relCaptcha">
													        <div id="captchaDiv" captcha="true" name="" style="padding: 20px;background:#fff;border: 1px solid rgb(222, 222, 222);box-sizing: border-box;width:98.8%"></div>
													        <input placeholder="Captcha" id="captchaText" name="captchaText" changeitem="SIGNUP_FORM_FIELD" style="margin-top: 5px; width: 98.7%; color: rgb(68, 68, 68); background-color: rgb(247, 247, 247); border: 2px solid rgb(36, 85, 141); padding: 3px; font-family: Arial; box-sizing: border-box; font-size: 16px; text-indent: 5px; height: 28px;" maxlength="100" type="text">
													        <span name="SIGNUP_REQUIRED" id="capRequired" style="color: rgb(180, 0, 0); margin-top: -16px; margin-right: -2px; float: right; font-family: Arial; font-size: 11px;">*</span>
												        </div>
											        </div>
											      <div style="clear: both"></div>
										      </div>
										      <input type="hidden" id="secretid" value="6LdNeDUUAAAAAG5l7cJfv1AA5OKLslkrOa_xXxLs"><!-- Captcha for Signup End--><!-- Other Lists Subscription Start-->
										      <div style=" border-bottom:#ebebeb dotted 1px; margin-top:10px; clear:both;"></div>
										      <div id="REQUIRED_FIELD_TEXT" changetype="REQUIRED_FIELD_TEXT" name="SIGNUP_REQUIRED" style="color: rgb(180, 0, 0); padding: 10px 10px 10px 0px; font-family: Arial; font-size: 11px;">*Required Fields</div>
										      <div style="padding:10px; text-align:center;">
											      <input type="button" action="Save" id="zcWebOptin" name="SIGNUP_SUBMIT_BUTTON" changetype="SIGNUP_SUBMIT_BUTTON_TEXT" style="cursor: pointer; appearance: none; color: rgb(255, 255, 255); background-color: rgb(58, 176, 226); padding: 5px 15px; border-color: rgb(58, 176, 226); text-align: center; outline: none medium; font-family: Arial; border-radius: 0px; border-width: 5px; font-size: 20px; background-position: center bottom; border-style: solid; background-repeat: repeat-x;" value="Submit">
										      </div>
									      </div>
								      </div>
							      </div><!-- Do not edit the below Zoho Campaigns hidden tags -->
							      <input type="hidden" id="fieldBorder" value="">
							      <input type="hidden" name="zc_trackCode" id="zc_trackCode" value="ZCFORMVIEW" onload="">
							      <input type="hidden" name="viewFrom" id="viewFrom" value="URL_ACTION">
							      <input type="hidden" id="submitType" name="submitType" value="optinCustomView">
							      <input type="hidden" id="lD" name="lD" value="1780bd372f86a666">
							      <input type="hidden" name="emailReportId" id="emailReportId" value="">
							      <input type="hidden" name="zx" id="cmpZuid" value="129205141">
							      <input type="hidden" name="zcvers" value="3.0">
							      <input type="hidden" name="oldListIds" id="allCheckedListIds" value="">
							      <input type="hidden" id="mode" name="mode" value="OptinCreateView">
							      <input type="hidden" id="zcld" name="zcld" value="1780bd372f86a666">
							      <input type="hidden" id="zctd" name="zctd" value="">
							      <input type="hidden" id="document_domain" value="">
							      <input type="hidden" id="zc_Url" value="wesu.maillist-manage.com">
							      <input type="hidden" id="new_optin_response_in" value="0">
							      <input type="hidden" id="duplicate_optin_response_in" value="0">
							      <input type="hidden" id="zc_formIx" name="zc_formIx" value="3z3b22ce1d9b8cf7daee424c8ee00ee0174003ab131107ab951f7d6343ab9d53f0"><!-- End of the campaigns hidden tags --></div>
					        </form>
				        </div>
			        </div>
		        </div>
		        <input type="hidden" id="isCaptchaNeeded" value="false">
		        <input type="hidden" id="superAdminCap" value="0">
		        <img src="https://wesu.maillist-manage.com/images/spacer.gif" onload="referenceSetter(this)" id="refImage" style="display:none;">
	        </div>
        </div>
        <div id="zcOptinOverLay" oncontextmenu="return false" style="display:none;text-align: center; background-color: rgb(0, 0, 0); opacity: 0.5; z-index: 100; position: fixed; width: 100%; top: 0px; left: 0px; height: 988px;"></div>
        <div id="zcOptinSuccessPopup" style="display:none;z-index: 9999;width: 800px; height: 40%;top: 84px;position: fixed; left: 26%;background-color: #FFFFFF;border-color: #E6E6E6; border-style: solid; border-width: 1px;  box-shadow: 0 1px 10px #424242;padding: 35px;">
	        <span style="position: absolute;top: -16px;right:-14px;z-index:99999;cursor: pointer;" id="closeSuccess">
		        <img src="https://wesu.maillist-manage.com/images/videoclose.png">
	        </span>
	        <div id="zcOptinSuccessPanel"></div>
        </div>
        <!--Zoho Campaigns Web-Optin Form Ends Here-->
          `
        }}
      />
    </div>
  )
}

export default WastewaterAuditForm
