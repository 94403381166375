import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '@components/layout'
import WastewaterAuditChecklist from '@components/forms/wastewater-audit-form'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

const WastewaterAuditChecklistPage = () => {
  return (
    <Layout pageTitle="Wastewater Audit Checklist">
      <Container fluid="xxl">
        <Row>
          <Col>
            <div className="hero-banner large-hero light-hero">
              <StaticImage
                className="hero-image"
                src="../../../media/wessuc_default_hero.png"
                alt=""
                aspectRatio={3 / 1}
                layout="fullWidth" 
              />
              <div className="hero-text">
                <h1>
                  Learn 10 steps to success for your industrial wastewater audit
                </h1>
              </div>
            </div>
            <div className="section"></div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="section-pad bg-gray">
        <Container fluid="xxl">
          <Row xl={2} lg={2} md={1} sm={1} xs={1}>
            <Col className="text-lg">
              <p className="bold-2 default-text">
                A wastewater audit is the best way to make informed recommendations on ways to save money on wastewater disposal, storage, transportation and treatment. This engaging 10-step checklist will help you...
              </p>
              <ul>
                <li>identify key people to include in you</li>
                <li>set (or refine) project goals</li>
                <li>draft a comprehensive plan</li>
                <li>know when, where and how to measure and sample</li>
                <li>identify potential cost savings</li>
              </ul>
              <p className="bold-2 default-text">The checklist includes</p>
              <ul>
                <li>an outline for a wastewater audit plan</li>
                <li>an example process flow diagram for wastewater</li>
                <li>advice on data collection and analysis</li>
                <li>insights to give your recommendations the best chance of implementation</li>
                <li>quick tips to ensure success</li>
              </ul>
            </Col>
            <Col>
              <WastewaterAuditChecklist />
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}

export default WastewaterAuditChecklistPage